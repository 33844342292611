import { Trade, TRADE_DEFAULT_OPTIONS } from '@/sdk/entities/trade';
import { loggingTrades, prepareDataForExactInOutRequest, tradeToSwapBestTrade } from '../utils';
import { toWei } from '@/sdk/utils';
import { Pair } from '@/sdk/entities/pair';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { chooseBestTrade } from '@/helpers/tradeFilter.helper';
import { PairSourceType } from '@/sdk/entities/pairSource';
import { Token } from '@/sdk/entities/token';
import { ISwapForm } from '../../models/swap-form.interface';
import { ISwapBestTrade } from '../../models/swap-best-trade.interface';
import { asyncFnCancelable } from '@/utils/promise';
import { TradeCalculator } from '../types';
import { calculateBestTradePipeline } from '../calculate-swap-best-trade';

const ExactInSwapBestTradeCalculator: TradeCalculator = {
  label: '[EXACT_IN_ROUTES]',

  calculateBestTrade: (swapForm: ISwapForm, pairs: Pair[]) => {
    return calculateBestTradeExactIn(...prepareDataForExactInOutRequest(swapForm, pairs, 'IN'));
  },

  toSwapBestTrade: (bestTrade: Trade) => {
    return exactInTradeToSwapBestTrade(bestTrade);
  },
};

export async function exactInRoutes(
  swapForm: ISwapForm,
  pairs: Pair[],
  settings: {
    signal: AbortSignal;
  },
): Promise<ISwapBestTrade> {
  return asyncFnCancelable<ISwapBestTrade>(
    () => calculateBestTradePipeline(swapForm, pairs, ExactInSwapBestTradeCalculator),
    settings.signal,
  );
}

async function calculateBestTradeExactIn(
  tokenIn: Token,
  tokenOut: Token,
  amountIn: string,
  pairs: Pair[],
  maxHops: 1 | 2 | 3,
  localOnly: boolean,
  isCrossChainTrade: boolean,
): Promise<Trade | undefined> {
  console.groupCollapsed('[CALCULATE_ROUTES_FROM_IN] calculate trades');

  let trades: Trade[] = [];

  pairs.forEach(pair => {
    console.log(`${pair.token0.symbol}-${pair.token1.symbol}`);
  });

  console.log(
    pairs,
    pairs.filter(pair => pair.pairSource.type === PairSourceType.CROSSCHAIN_PORTFOLIO).length,
  );

  // if (!pairs.length) {
  //   return resolve(trades);
  // }

  const tokenAmountIn = new TokenAmount(tokenIn, amountIn);

  const tradeOptions = Object.assign(TRADE_DEFAULT_OPTIONS, {
    maxHops,
    isCrossChainTrade,
    enabledLocalPriority: localOnly,
  });

  trades = Trade.bestTradeExactIn(pairs, tokenAmountIn, tokenOut, tradeOptions);
  loggingTrades('trades : ', trades);

  trades = chooseBestTrade(trades, localOnly, tokenIn, tokenOut);
  loggingTrades('best trades : ', trades);

  const bestTrade = trades[0];
  console.log('best trade [ORIGIN] : ', bestTrade);

  console.groupEnd();
  return bestTrade;
}

function exactInTradeToSwapBestTrade(bestTrade: Trade) {
  return tradeToSwapBestTrade(bestTrade, {
    amountOut: toWei(
      bestTrade.outputAmount.toFixed(),
      bestTrade.outputAmount.currency.decimals,
    ).toString(),
  });
}
