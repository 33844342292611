import { MAX_HOPS, MIN_HOPS, Trade } from '@/sdk/entities/trade';
import { ISwapBestTrade } from '../models/swap-best-trade.interface';
import { PERCENTAGE_DECIMAL_PRECISION } from '@/constants/INTERFACE_SETTINGS';
import { toWei } from '@/sdk/utils';
import { ISwapForm } from '../models/swap-form.interface';
import { safeParseUnits } from '@/helpers/utils';
import { Pair } from '@/sdk/entities/pair';
import { Token } from '@/sdk/entities/token';

export function prepareDataForExactInOutRequest(
  swapForm: ISwapForm,
  pairs: Pair[],
  requestType: 'IN' | 'OUT',
): [Token, Token, string, Pair[], 1 | 2 | 3, boolean, boolean] {
  return [
    swapForm.input.token!,
    swapForm.output.token!,
    requestType === 'IN'
      ? safeParseUnits(swapForm.input.amount, swapForm.input.token!.decimals)
      : safeParseUnits(swapForm.output.amount, swapForm.output.token!.decimals),
    pairs,
    swapForm.settings.enableMultiHops ? MAX_HOPS : MIN_HOPS,
    !!swapForm.settings.localSwapPriority,
    swapForm.input.token!.chainId !== swapForm.output.token!.chainId,
  ];
}

export function buildSwapBestTradeWhenSwapSameTokens(swapForm: ISwapForm): ISwapBestTrade {
  const swapToken = swapForm.output.estimated ? swapForm.input : swapForm.output;
  const estimatedAmount = safeParseUnits(swapToken.amount, swapToken.token!.decimals);

  return {
    route: {
      path: [swapToken.token!.address],
      chains: [swapToken.token!.chainId.toString()],
      portfolios: [],
      crossChainPortfolioIndex: '-1',
      portfolioNames: [],
    },
    amountOut: swapForm.output.estimated ? estimatedAmount : undefined,
    amountIn: swapForm.input.estimated ? estimatedAmount : undefined,
    priceImpact: '0',
    lpFee: '0',
    crossChainFee: '0',
  };
}

export function tradeToSwapBestTrade(
  bestTrade: Trade,
  amountInOrOut: { amountOut?: string; amountIn?: string },
): ISwapBestTrade {
  return {
    route: {
      path: bestTrade.route.path.map(t => t.address),
      chains: bestTrade.route.path.map(t => t.chainId.toString()),
      portfolios: bestTrade.route.pairs.map(p => p.pairSource.address),
      crossChainPortfolioIndex: '-1',
      // portfolioNames: bestTrade.route.pairs.map(p => p.pairSource.name),
      portfolioNames: [],
    },
    ...amountInOrOut,
    priceImpact: toWei(bestTrade.priceImpact.toFixed(), PERCENTAGE_DECIMAL_PRECISION).toString(),
    lpFee: '0',
    crossChainFee: '0',
  };
}

export function loggingTrades(label = 'TRADES', trades: Trade[]): void {
  console.groupCollapsed(label);
  const tradesLog: any[] = [];
  const logDecimals = 6;
  trades.forEach(trade => {
    tradesLog.push({
      route: trade.route,
      amountIn: trade.inputAmount?.toExact(),
      amountOut: trade.outputAmount?.toExact(),
      priceImpact: trade.priceImpact.toFixed(logDecimals),
    });
  });
  console.log(tradesLog);
  console.groupEnd();
}
