import _ from 'lodash';
import { computed, ref } from 'vue';
import { asyncFnCancelable } from '@/utils/promise';
import { ISwapBestTrade } from './models/swap-best-trade.interface';
import { ISwapForm } from './models/swap-form.interface';
import { isSwapETHForWETH, isSwapWETHForETH } from './swap-form-methods';
import { usePortfolios } from '@/composables/portfolios/usePortfolios';
import { buildSwapBestTradeWhenSwapSameTokens } from './swap-best-trade';
import { exactInRoutes, exactOutRoutes } from '@/store/modules/swap/swap-best-trade';

export const useSwapBestTrade = () => {
  const { allPortfoliosPairs } = usePortfolios();

  const swapFormUpdateRequest = ref<Generator<Promise<ISwapBestTrade>>>();

  let bestTradeRequestController = new AbortController();

  function resetBestTradeProducer() {
    swapFormUpdateRequest.value = undefined;
  }

  function abortCurrentBestTrade() {
    bestTradeRequestController.abort();
  }

  function abortPreviousBestTradeRequest() {
    const prevAbortController = bestTradeRequestController;
    bestTradeRequestController = new AbortController();
    prevAbortController.abort();
  }

  function* bestTradeGenerator(swapFromForExactInOutRequest: ISwapForm) {
    const swapFormCopy: ISwapForm = _.cloneDeep(swapFromForExactInOutRequest);
    while (true) {
      if (
        swapFormCopy.isSwapSameTokensFromMilkomedaToCardano ||
        isSwapETHForWETH(swapFormCopy) ||
        isSwapWETHForETH(swapFormCopy)
      ) {
        yield asyncFnCancelable<ISwapBestTrade>(
          () =>
            // NOTE: we use Promise and Timeout for UX - see skeleton on UI.
            new Promise<ISwapBestTrade>(resolve => {
              setTimeout(() => {
                resolve(buildSwapBestTradeWhenSwapSameTokens(swapFormCopy));
              }, 1000);
            }),
          bestTradeRequestController.signal,
        );
      } else if (swapFormCopy.output.estimated) {
        yield exactInRoutes(swapFormCopy, allPortfoliosPairs.value, {
          signal: bestTradeRequestController.signal,
        });
      } else if (swapFormCopy.input.estimated) {
        yield exactOutRoutes(swapFormCopy, allPortfoliosPairs.value, {
          signal: bestTradeRequestController.signal,
        });
      }
    }
  }

  function prepareBestTrade(swapForm: ISwapForm): void {
    swapFormUpdateRequest.value = undefined;
    swapFormUpdateRequest.value = bestTradeGenerator(swapForm);
  }

  const doSwapBestTrade = async () => {
    abortPreviousBestTradeRequest();

    // NOTE:
    // `swapFormUpdateRequest` contains copy of `swapForm`
    const bestTradeResult: ISwapBestTrade = await swapFormUpdateRequest.value?.next().value;

    return bestTradeResult;
  };

  return {
    isExistBestTradeProducer: computed(() => {
      return swapFormUpdateRequest.value === undefined;
    }),
    resetBestTradeProducer,
    abortCurrentBestTrade,
    bestTradeGenerator,
    prepareBestTrade,
    doSwapBestTrade,
  };
};
