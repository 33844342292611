import { safeParseUnits } from '@/helpers/utils';
import { ethers } from 'ethers';
import { ISwapForm } from './models/swap-form.interface';
import BigNumber from 'bignumber.js';
import { ISwapBestTrade } from './models/swap-best-trade.interface';
import { getInstanceOfRouterContract } from './swap-transaction-methods';
import { fromWei, toWei } from '@/sdk/utils';
import { Token } from '@/sdk/entities/token';

type AmountsEstimationResult = {
  amounts: BigNumber[];
  fees: number[];
  priceImpacts: number[];
};

export async function getAmountsOutWithPortfolios(
  swapForm: ISwapForm,
  routerContract: ethers.Contract,
): Promise<AmountsEstimationResult> {
  console.groupCollapsed('[SWAP:OUT:ESTIMATION] getAmountsOutWithPortfolios');

  const amountIn = safeParseUnits(swapForm.input.amount, swapForm.input.token?.decimals).toString();
  const path = swapForm.bestTrade.route.path;
  const portfolios = swapForm.bestTrade.route.portfolios;
  const destinationChain =
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId;
  const firstXChainPortfolioNum =
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex;

  const params = [amountIn, path, portfolios, destinationChain, firstXChainPortfolioNum];

  console.log('params: ', params);
  console.groupEnd();

  return routerContract.getAmountsOutWithPortfolios(...params);
}

export async function getAmountsInWithPortfolios(
  swapForm: ISwapForm,
  routerContract: ethers.Contract,
): Promise<AmountsEstimationResult> {
  console.groupCollapsed('[SWAP:IN:ESTIMATION] getAmountsInWithPortfolios');

  const amountOut = safeParseUnits(
    swapForm.output.amount,
    swapForm.output.token?.decimals,
  ).toString();
  const path = swapForm.bestTrade.route.path;
  const portfolios = swapForm.bestTrade.route.portfolios;
  const destinationChain =
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.input.token?.chainId;
  const firstXChainPortfolioNum =
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex;

  const params = [amountOut, path, portfolios, destinationChain, firstXChainPortfolioNum];

  console.log('params: ', params);
  console.groupEnd();

  return routerContract.getAmountsInWithPortfolios(...params);
}

// Stable swap
export async function changeAmountEstimateWhenBestTradeHasStableSwap(
  swapForm: ISwapForm,
  bestTradeResult: ISwapBestTrade,
): Promise<ISwapBestTrade> {
  const routerContract = getInstanceOfRouterContract();
  const swapFormForEstimate: ISwapForm = {
    ...swapForm,
    bestTrade: bestTradeResult,
  };

  if (bestTradeResult.amountOut) {
    const { amounts } = await getAmountsOutWithPortfolios(swapFormForEstimate, routerContract);

    return {
      ...bestTradeResult,
      amountOut: amounts.at(-1)?.toString(),
    };
  }

  if (bestTradeResult.amountIn) {
    const { amounts } = await getAmountsInWithPortfolios(swapFormForEstimate, routerContract);

    return {
      ...bestTradeResult,
      amountIn: amounts.at(0)?.toString(),
    };
  }

  return bestTradeResult;
}

export async function changeAmountAndFeeAndPriceImpactByEstimate(
  swapForm: ISwapForm,
  bestTradeResult: ISwapBestTrade,
): Promise<ISwapBestTrade> {
  const routerContract = getInstanceOfRouterContract();
  const swapFormForEstimate: ISwapForm = {
    ...swapForm,
    bestTrade: bestTradeResult,
  };

  if (bestTradeResult.amountOut) {
    const { amounts, fees, priceImpacts } = await getAmountsOutWithPortfolios(
      swapFormForEstimate,
      routerContract,
    );

    console.groupCollapsed('[SWAP:OUT:ESTIMATION] getAmountsOutWithPortfolios RESULT');
    console.table({
      amounts: amounts.map(a => a.toString()),
      fees: fees.map(f => f.toString()),
      priceImpacts: priceImpacts.map(p => p.toString()),
    });
    console.groupEnd();

    const amountOutWei = amounts.at(-1)?.toString();

    if (!amountOutWei || BigInt(amountOutWei) <= 0) {
      throw Error('Route not found');
    }

    return {
      ...bestTradeResult,
      amountOut: amountOutWei,
      lpFee: calculateLiquidityProviderFeeInWei(
        fees,
        toWei(swapForm.input.amount ?? 0, swapForm.input.token?.decimals).toString(),
        swapForm.input.token!,
      ),
      priceImpact: Math.max(...priceImpacts.map(p => +p)).toString(),
    };
  }

  if (bestTradeResult.amountIn) {
    const { amounts, fees, priceImpacts } = await getAmountsInWithPortfolios(
      swapFormForEstimate,
      routerContract,
    );

    console.groupCollapsed('[SWAP:IN:ESTIMATION] getAmountsInWithPortfolios RESULT');
    console.table({
      amounts: amounts.map(a => a.toString()),
      fees: fees.map(f => f.toString()),
      priceImpacts: priceImpacts.map(p => p.toString()),
    });
    console.groupEnd();

    const amountInWei = amounts.at(0)?.toString();

    if (!amountInWei || BigInt(amountInWei) <= 0) {
      throw Error('Route not found');
    }

    return {
      ...bestTradeResult,
      amountIn: amountInWei,
      lpFee: calculateLiquidityProviderFeeInWei(fees, amountInWei ?? '0', swapForm.input.token!),
      priceImpact: Math.max(...priceImpacts.map(p => +p)).toString(),
    };
  }

  return bestTradeResult;
}

const FEE_DECIMALS = 4;
// 10000 -> 100% -> 1

function calculateLiquidityProviderFeeInWei(fees: number[], amountInWei: string, token: Token) {
  const totalFee = fees.reduce((a, b) => a + b, 0);
  const decimals = token.decimals + FEE_DECIMALS;

  return toWei(
    fromWei(BigNumber(amountInWei).multipliedBy(totalFee), decimals),
    token.decimals,
  ).toFixed(0);
}
