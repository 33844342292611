import { toWei } from '@/sdk/utils';
import { loggingTrades, prepareDataForExactInOutRequest, tradeToSwapBestTrade } from '../utils';
import { Trade, TRADE_DEFAULT_OPTIONS } from '@/sdk/entities/trade';
import { ISwapForm } from '../../models/swap-form.interface';
import { Pair } from '@/sdk/entities/pair';
import { ISwapBestTrade } from '../../models/swap-best-trade.interface';
import { asyncFnCancelable } from '@/utils/promise';
import { Token } from '@/sdk/entities/token';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { chooseBestTrade } from '@/helpers/tradeFilter.helper';
import { TradeCalculator } from '../types';
import { calculateBestTradePipeline } from '../calculate-swap-best-trade';
import { PairSourceType } from '@/sdk/entities/pairSource';

const ExactOutSwapBestTradeCalculator: TradeCalculator = {
  label: '[EXACT_OUT_ROUTES]',

  calculateBestTrade: (swapForm: ISwapForm, pairs: Pair[]) => {
    return calculateBestTradeExactOut(...prepareDataForExactInOutRequest(swapForm, pairs, 'OUT'));
  },

  toSwapBestTrade: (bestTrade: Trade) => {
    return exactOutTradeToSwapBestTrade(bestTrade);
  },
};

export async function exactOutRoutes(
  swapForm: ISwapForm,
  pairs: Pair[],
  settings: {
    signal: AbortSignal;
  },
): Promise<ISwapBestTrade> {
  return asyncFnCancelable<ISwapBestTrade>(
    () => calculateBestTradePipeline(swapForm, pairs, ExactOutSwapBestTradeCalculator),
    settings.signal,
  );
}

async function calculateBestTradeExactOut(
  tokenIn: Token,
  tokenOut: Token,
  amountOut: string,
  pairs: Pair[],
  maxHops: 1 | 2 | 3,
  localOnly: boolean,
  isCrossChainTrade: boolean,
): Promise<Trade | undefined> {
  console.groupCollapsed('[CALCULATE_ROUTES_FROM_OUT] calculate trades');

  let trades: Trade[] = [];

  pairs.forEach(pair => {
    console.log(`${pair.token0.symbol}-${pair.token1.symbol}`);
  });

  console.log(
    pairs,
    pairs.filter(pair => pair.pairSource.type === PairSourceType.CROSSCHAIN_PORTFOLIO).length,
  );

  if (!pairs.length) {
    throw Error('Can not find route, cause pairs is empty.');
  }

  const tokenAmountOut = new TokenAmount(tokenOut, amountOut);

  const tradeOptions = Object.assign(TRADE_DEFAULT_OPTIONS, {
    maxHops,
    isCrossChainTrade,
    enabledLocalPriority: localOnly,
  });

  trades = Trade.bestTradeExactOut(pairs, tokenIn, tokenAmountOut, tradeOptions);
  loggingTrades('trades : ', trades);

  trades = chooseBestTrade(trades, localOnly, tokenIn, tokenOut);
  loggingTrades('best trades : ', trades);

  const bestTrade = trades[0];
  console.log('best trade : ', bestTrade);

  console.groupEnd();
  return bestTrade;
}

function exactOutTradeToSwapBestTrade(bestTrade: Trade) {
  return tradeToSwapBestTrade(bestTrade, {
    amountIn: toWei(
      bestTrade.inputAmount.toFixed(),
      bestTrade.inputAmount.currency.decimals,
    ).toString(),
  });
}
