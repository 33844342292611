import { computed } from 'vue';
import { useStore } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { Portfolio } from '@/sdk/entities/portfolio';
import { Pair } from '@/sdk/entities/pair';

export function usePortfolios() {
  const store = useStore();

  const getPortfolios = computed<Portfolio[]>(() => {
    return store.getters[`${MODULE_NAMES.PORTFOLIOS}/getPortfolios`] ?? [];
  });

  const getPortfoliosByAddresses = computed(() => {
    return store.getters[`${MODULE_NAMES.PORTFOLIOS}/getPortfoliosByAddresses`] ?? {};
  });

  const isPortfoliosUpdated = computed<boolean>(() => {
    return store.state.portfolios.isPortfoliosUpdated;
  });

  const isPortfoliosStatisticLoaded = computed(() => {
    return store.state.portfolios.isStatisticLoaded;
  });

  const isPortfoliosStoreReady = computed<boolean>(() => {
    return store.state.portfolios.isStoreReady;
  });

  function getPortfolioByAddress(address: string): Portfolio | undefined {
    return getPortfoliosByAddresses.value[address];
  }

  const allPortfoliosPairs = computed(() => {
    const portfolios = getPortfolios.value;
    const pairs: Pair[] = portfolios.flatMap(portfolio =>
      Object.values(portfolio.portfolioPairs.pairs),
    );
    return pairs || [];
  });

  return {
    getPortfolios,
    isPortfoliosUpdated,
    isPortfoliosStatisticLoaded,
    isPortfoliosStoreReady,
    getPortfolioByAddress,
    allPortfoliosPairs,
  };
}
