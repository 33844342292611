import BigNumber from 'bignumber.js';
import { ISwapForm } from './models/swap-form.interface';
import { MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';

export function checkIfSwapFormHasNoErrors(swapForm: ISwapForm): boolean {
  console.log('checkIfSwapFormHasNoErrors', swapForm);
  swapForm.errors = [];
  if (
    !swapForm.isSwapSameTokensFromMilkomedaToCardano &&
    swapForm.input.token &&
    swapForm.output.token
  ) {
    if (swapForm.input.token.isSameSymbol(swapForm.output.token)) {
      swapForm.errors.push('sameTokens');
      return false;
    }
  }
  if (!swapForm.input.token) return false;
  if (!swapForm.output.token) return false;
  if (!swapForm.input.estimated && !swapForm.output.estimated) return false;
  if (
    swapForm.input.estimated &&
    swapForm.output.amount &&
    !validatePositiveNumber(swapForm.output.amount)
  )
    return false;
  if (
    swapForm.output.estimated &&
    swapForm.input.amount &&
    !validatePositiveNumber(swapForm.input.amount)
  )
    return false;

  const swapAmount = swapForm.input.estimated ? swapForm.output.amount : swapForm.input.amount;
  if (
    swapForm.isSwapSameTokensFromMilkomedaToCardano &&
    swapForm.input.token &&
    swapForm.input.token.isETHToken() &&
    swapAmount
  ) {
    if (+swapAmount < +MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA) {
      swapForm.errors.push('lessMinAmountForUnwrap');
      return false;
    }
  }
  return true;
}

export function isSwapETHForWETH(swapForm: ISwapForm): boolean {
  if (!swapForm.input.token || !swapForm.output.token) return false;
  return swapForm.input.token?.isETHToken() && swapForm.output.token?.isWETHToken();
}

export function isSwapWETHForETH(swapForm: ISwapForm): boolean {
  if (!swapForm.input.token || !swapForm.output.token) return false;
  return swapForm.input.token?.isWETHToken() && swapForm.output.token?.isETHToken();
}

function validatePositiveNumber(value: string): boolean {
  try {
    const bnValue = new BigNumber(value);
    if (bnValue.gt(0)) return true;
  } catch (e) {
    return false;
  }
  return false;
}
