import { changeAmountAndFeeAndPriceImpactByEstimate } from '../swap-estimate-methods';
import { TradeCalculator } from './types';
import { ISwapForm } from '../models/swap-form.interface';
import { Pair } from '@/sdk/entities/pair';
import { ISwapBestTrade } from '../models/swap-best-trade.interface';

export async function calculateBestTradePipeline(
  swapForm: ISwapForm,
  pairs: Pair[],
  tradeCalculator: TradeCalculator,
): Promise<ISwapBestTrade> {
  return new Promise<ISwapBestTrade>((resolve, reject) => {
    console.groupCollapsed(tradeCalculator.label);

    tradeCalculator
      .calculateBestTrade(swapForm, pairs)
      .then(bestTrade => {
        if (!bestTrade) {
          throw Error('Route not found');
        }

        const swapBestTrade = tradeCalculator.toSwapBestTrade(bestTrade);
        console.log('swapBestTrade [ORIGIN] : ', swapBestTrade);
        return swapBestTrade;
      })
      .then(swapBestTrade => {
        return changeAmountAndFeeAndPriceImpactByEstimate(swapForm, swapBestTrade);
      })
      .then(finalSwapBestTrade => {
        resolve(finalSwapBestTrade);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {
        console.groupEnd();
      });
  });
}
